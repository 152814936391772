.ck-editor {
  a {
    color: $studio-cyan;
    text-decoration: underline;

    &:hover {
      color: $studio-cyan;
    }
  }

  .ck-content {
    blockquote {
      font-style: initial;
    }
  }

  .legacy-shortcode {
    border: 1px solid $studio-text-gray;
  }
}
