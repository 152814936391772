h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

a {
  cursor: pointer;
  color: $studio-black;

  &:hover {
    color: $studio-red;
  }
}

a.underline {
  text-decoration: underline;
}

.studio-card {
  background-color: white;
  box-shadow: 0 1px rgb(0 0 0 / 23%);
}

.noflex {
  flex: none;
}

.hover-pointer:hover {
  cursor: pointer;
}

.font-size-75 {
  font-size: 75% !important;
}
