.resource-embed-widget {
  .resource-list {
    max-height: 225px;
    overflow: scroll;
    border-left: 1px solid $form-control-gray;
    border-right: 1px solid $form-control-gray;
    border-bottom: 1px solid $form-control-gray;
    border-radius: 0 0 0.25rem 0.25rem;

    .resource {
      cursor: pointer;
    }
  }
}

.ck-editor {
  .resource-link {
    border: 1px solid $studio-gray;
    padding: 0 3px;
    border-radius: 5px;
  }

  .embedded-resource {
    border: 1px solid $studio-gray;

    img {
      max-height: 225px;

      // CKEditor puts a min-width property on images
      // which screws everything up, so we need to unset
      // it here
      min-width: initial !important;
      max-width: 175px !important;
    }

    .resource-info {
      font-size: 14px;
      word-break: break-all;
    }
  }
}
