$std-max-width: 1447px; // arbitrary value, copied from ocw-next
$narrow-max-width: 800px;
$std-font-size: 1rem;
$caption-font-size: 0.8rem;
$light-gray-bg: #eee;
$slate-gray: rgb(112 128 144);
$error-red: #db312a;
$studio-background: #f5f5f5;
$studio-green: #00bc87;
$studio-black: #030303;
$studio-text-gray: #b3b3b3;
$studio-gray: #e4e4e4;
$studio-red: #a41e34;
$studio-cyan: #418786;
$studio-yellow: #fff700;
$studio-orange: #ff8c00;
$studio-dark-gray: #5d5d5d;
$dialog-gray: #d9d9d9;
$form-control-gray: #ced4da;
