.dashboard {
  .content {
    flex-grow: 1;
    padding: 15px;
  }

  .site-description {
    color: $studio-text-gray;
  }

  .site-search-input {
    height: 45px;
    width: unset;
  }
}
